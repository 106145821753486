import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Container = makeShortcode("Container");
const Grids = makeShortcode("Grids");
const Youtube = makeShortcode("Youtube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Container mdxType="Container">
      <h1>{`Sample Page`}</h1>
      <p><strong parentName="p">{`gatsby-theme-musician`}</strong>{` comes with some components, utility classes, and plugins you can use out-of-the-box to help you write your content. You can see them in action here, and open `}<inlineCode parentName="p">{`sample-page.mdx`}</inlineCode>{` in your content folder to see the source.`}</p>
      <p>{`For more information about writing in MDX, `}<a parentName="p" {...{
          "href": "https://mdxjs.com/"
        }}>{`go to their website`}</a>{`.`}</p>
      <hr></hr>
      <h2>{`🎸 Theme Components`}</h2>
      <p>{`You can see these theme-specific components in action on the landing page (`}<inlineCode parentName="p">{`artist-landing-page.mdx`}</inlineCode>{`). Read more about these components in the `}<a parentName="p" {...{
          "href": "https://github.com/ekafyi/gatsby-theme-musician/blob/master/docs/components.md"
        }}>{`Components Documentation`}</a>{`.`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Banner`}</strong></p>
          <p parentName="li">{`Display the artist name, tagline, and social links configured in `}<inlineCode parentName="p">{`src/gatsby-theme-musician/config`}</inlineCode></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Music`}</strong></p>
          <p parentName="li">{`Display entries in `}<inlineCode parentName="p">{`releases.yml`}</inlineCode></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Shows`}</strong></p>
          <p parentName="li">{`Display entries in `}<inlineCode parentName="p">{`shows.yml`}</inlineCode></p>
        </li>
      </ul>
      <hr></hr>
      <h2>{`📐 Layout`}</h2>
      <h3>{`Container`}</h3>
      <p>{`By default, page content takes the entire screen width. Use `}<inlineCode parentName="p">{`<Container>`}</inlineCode>{` to create a centered layout block with a maximum width (the default is 1024 px). Try removing the `}<inlineCode parentName="p">{`<Container>`}</inlineCode>{` opening and closing tags at the top and the bottom of this page and see what the page looks like.`}</p>
      <h3>{`Grids`}</h3>
      <p>{`Use the `}<inlineCode parentName="p">{`<Grids>`}</inlineCode>{` component to display your content in a grid. The grid content must be separated by two line-breaks each. (On small screens, the columns expand to entire screen width.)`}</p>
      <Grids mdxType="Grids">
        <p><strong parentName="p">{`hello world`}</strong></p>
        <p><strong parentName="p">{`hello!`}</strong></p>
        <p><strong parentName="p">{`what's up?`}</strong></p>
      </Grids>
      <p>{`By default, the Grids consist of 3 columns. You can modify it by adding the `}<inlineCode parentName="p">{`columns`}</inlineCode>{` attribute.`}</p>
      <Grids columns="5" mdxType="Grids">
        <p><strong parentName="p">{`this grid`}</strong></p>
        <p><strong parentName="p">{`contains`}</strong></p>
        <p><strong parentName="p">{`five`}</strong></p>
        <p><strong parentName="p">{`columns!`}</strong></p>
        <p><strong parentName="p">{`hurray!`}</strong></p>
      </Grids>
      <hr></hr>
      <h2>{`🔹 Basic Elements`}</h2>
      <h3>{`Paragraphs and Blocks`}</h3>
      <p>{`This is a regular text paragraph.`}</p>
      <p className="text-center">This paragraph is centered using <code>className="text-center"</code> attribute.</p>
      <p className="text-right">And this paragraph is aligned right using <code>className="text-right"</code> attribute.</p>
      <div className="text-center">Other than paragraphs, these class names also work with the <code>{`<div>`}</code> element.</div>
      <p>&nbsp;</p>
      <h3>{`Lists`}</h3>
      <p>{`This is an unordered list with child lists:`}</p>
      <ul>
        <li parentName="ul">{`Vestibulum tristique tempor neque quis malesuada`}
          <ul parentName="li">
            <li parentName="ul">{`Duis dictum vitae tortor a porttitor`}
              <ul parentName="li">
                <li parentName="ul">{`Duis dictum vitae tortor a porttitor`}</li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`Curabitur fringilla eros justo, id consequat dui scelerisque vitae`}</li>
      </ul>
      <p>{`This is an ordered list:`}</p>
      <ol>
        <li parentName="ol">{`Vestibulum tristique tempor neque quis malesuada`}</li>
        <li parentName="ol">{`Duis dictum vitae tortor a porttitor`}</li>
        <li parentName="ol">{`Curabitur fringilla eros justo, id consequat dui scelerisque vitae`}</li>
      </ol>
      <h3>{`Images`}</h3>
      <p>{`Regular Markdown image`}</p>
      <p><img parentName="p" {...{
          "src": "https://raw.githubusercontent.com/ekafyi/gatsby-theme-musician/master/screenshot_with_bg.png",
          "alt": "gatsby-theme-musician demo site"
        }}></img></p>
      <p>{`Regular Markdown image with hyperlink (click image to open)`}</p>
      <p><a parentName="p" {...{
          "href": "https://github.com/ekafyi/gatsby-theme-musician"
        }}><img parentName="a" {...{
            "src": "https://raw.githubusercontent.com/ekafyi/gatsby-theme-musician/master/screenshot_with_bg.png",
            "alt": "gatsby-theme-musician demo site"
          }}></img></a></p>
      <p>{`HTML image with `}<inlineCode parentName="p">{`figcaption`}</inlineCode></p>
      <figure>{`
	`}<img parentName="figure" {...{
          "src": "https://raw.githubusercontent.com/ekafyi/gatsby-theme-musician/master/screenshot_with_bg.png",
          "alt": "gatsby-theme-musician demo site"
        }}></img>{`
	`}<figcaption parentName="figure">{`Photo © Eka 2019 | `}<a parentName="figcaption" {...{
            "href": "https://github.com/ekafyi/gatsby-theme-musician"
          }}>{`Source`}</a></figcaption>
      </figure>
      <h3>{`Links`}</h3>
      <p>{`You can create hyperlinks with Markdown formatting or with regular HTML anchor element.`}</p>
      <p>{`You can give them the appearance of a button with the following class names: `}<inlineCode parentName="p">{`.gtm-button`}</inlineCode>{`, `}<inlineCode parentName="p">{`.gtm-button-primary`}</inlineCode>{`, and `}<inlineCode parentName="p">{`.gtm-button-invert`}</inlineCode>{`.`}</p>
      <a href="#" className="gtm-button">Button link</a> &nbsp; <a href="#" className="gtm-button-primary">Primary button link</a> &nbsp; <a href="#" className="gtm-button-invert">Inverted button link</a>
      <p>&nbsp;</p>
      <h3>{`Blockquote`}</h3>
      <blockquote>
        <p parentName="blockquote">{`Here is a blockquote. Lorem ipsum dolor sit amet, vis doctus iriure recteque te, alii disputationi eu eum.`}</p>
        <p parentName="blockquote">{`Mollis aliquam senserit quo ad. Eam ad regione delenit gloriatur. At eos oporteat consetetur, id pri adhuc corpora. Stet vocibus explicari vim in, sed ne gloriatur elaboraret.`}</p>
        <cite>Eka</cite>
      </blockquote>
      <hr></hr>
      <h2>{`📻 Media`}</h2>
      <p>{`You can embed `}<strong parentName="p">{`Youtube`}</strong>{` videos by using the `}<inlineCode parentName="p">{`<Youtube>`}</inlineCode>{` component included in this theme.`}</p>
      <Youtube url="https://www.youtube.com/watch?v=dQw4w9WgXcQ" mdxType="Youtube" />
      <p>{`You can also embed from `}<strong parentName="p">{`Spotify`}</strong>{` and `}<strong parentName="p">{`Soundcloud`}</strong>{` simply by `}<em parentName="p">{`inserting the link in a separate line`}</em>{` with two line breaks before and after it.`}</p>
      <p>{`To embed from Spotify, insert a link to the album, track, playlist, or artist. You can get the link by clicking the ellipsis (⋯) after the item title on Spotify and choosing `}<em parentName="p">{`"Copy `}{`[Album, Track, etc]`}{` Link"`}</em>{`.`}</p>
      <p><a parentName="p" {...{
          "href": "https://open.spotify.com/album/6XhjNHCyCDyyGJRM5mg40G"
        }}><iframe parentName="a" {...{
            "src": "https://open.spotify.com/embed/album/6XhjNHCyCDyyGJRM5mg40G",
            "width": 400,
            "height": 400,
            "frameBorder": "0",
            "allowTransparency": "true",
            "allow": "encrypted-media"
          }}></iframe></a></p>
      <p>{`To embed from Soundcloud, insert a link to a track or a set.`}</p>
      <p><a parentName="p" {...{
          "href": "https://soundcloud.com/andreasedstr-m/rick-astley-never-gonna-give"
        }}><iframe parentName="a" {...{
            "width": 400,
            "height": 400,
            "scrolling": "no",
            "frameBorder": "no",
            "allow": "autoplay",
            "src": "https://w.soundcloud.com/player/?url=s://soundcloud.com/andreasedstr-m/rick-astley-never-gonna-give&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          }}></iframe></a></p>
      <p>{`To center the embedded Spotify or Soundcloud media, wrap it in a centered div.`}</p>
      <div className="text-center">
        <p><a parentName="p" {...{
            "href": "https://open.spotify.com/album/6XhjNHCyCDyyGJRM5mg40G"
          }}><iframe parentName="a" {...{
              "src": "https://open.spotify.com/embed/album/6XhjNHCyCDyyGJRM5mg40G",
              "width": 400,
              "height": 400,
              "frameBorder": "0",
              "allowTransparency": "true",
              "allow": "encrypted-media"
            }}></iframe></a></p>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      